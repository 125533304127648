.frequent-draws {
  margin-bottom: 30px;

  &__header {

  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: block;
    padding: 16px 16px;
    margin-bottom: 17px;

    background-color: #FFF;

    border-radius: 20px;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.12);

    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    &-img {
      position: absolute;
      top: 16px;
      right: 16px;

      height: 68px;
      max-width: 80px;
      object-fit: contain;
      object-position: center;
    }

    &-sub-header {
      margin-bottom: 6px;

      color: rgba(0, 0, 0, 0.435);
      font-size: 10px;
      line-height: 1em;
      font-weight: 500;
    }

    &-header {
      max-width: 165px;
      margin-bottom: 8px;

      color: #212121;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
    }

    &-bottom {
      display: flex;
      align-items: flex-end;

      @media (max-width: 345px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-superprize {
      margin-right: 5px;
    }

    &-prizes {

    }

    &-small-header {
      margin-bottom: 3px;

      color: #909090;
      font-size: 10px;
      line-height: 1em
    }

    &-badge {
      background-color: #EEEEEE;
    }

    &-from {
      position: absolute;
      right: 16px;
      bottom: 16px;

      padding: 10px 12px;

      color: #FFF;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;

      background-color: #000;
      border-radius: 12px;
    }
  }
}
