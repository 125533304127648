.footer {
  margin: 0 -15px;
  padding: 0 15px 40px;

  background-image: url(./bg.svg);
  background-position: center 18%;

  &__title {
    margin-top: 10px;

    color: #909090;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }

  &__notice {
    position: relative;
    max-width: 278px;
    margin-bottom: 16px;
    padding-left: 36px;

    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;

    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 28px;
      height: 28px;

      transform: translateY(-50%);

      background-image: url(./notice.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__button {
    display: block;
    width: 100%;
    padding: 16px 0;
    margin-bottom: 33px;

    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
  
    background-color: #000;
    border-radius: 12px;
  }

  &__phone {
    margin-bottom: 33px;

    &-socials {
       margin-bottom: 10px !important;
    }

    &-number {
      color: #212121;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.2px;
      font-weight: bold;
      text-decoration: none;
    }

    &-title {
      
    }
  }

  &__socials {
    margin-bottom: 33px;

    &-list {
      display: flex;
    }

    &-item {
      display: block;
      margin-right: 10px;
      width: 38px;
      height: 38px;

      /* background-image: url(); */
      background-repeat: no-repeat;

      &:last-child {
        margin-right: 0;
      }

      &-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &--vk {
      }

      &--facebook {

      }

      &--ok {

      }

      &--instagram {

      }

      &--youtube {

      }

      &--twitter {

      }

      &--telegram {

      }
    }

    &-list--small &-item {
      @media (max-width: 350px) {
        width: 32px;
        height: 32px;
      }
    }

    &-title {
      margin-top: 16px;
    }
  }

  &__payment {
    margin-bottom: 33px;

    &-img {
      width: 205px;
      margin-top: -1px;
      margin-left: -7px;
    }

    &-title {

    }
  }

  &__secure {
    display: flex;
    margin-bottom: 33px;

    &-text {
      margin-right: 20px;

      color: rgba(0, 0, 0, 0.7);
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
    }

    &-link {
      text-decoration: none;
    }

    &-img {
      width: 103px;
      object-fit: contain;
    }
  }

  &__links {
    max-width: 288px;

    &-item {
      margin-bottom: 32px;
      display: inline-block;

      color: #000;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      font-weight: 500;
    }
  }
}
