.interesting {
  margin-bottom: 26px;

  &__header {

  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    height: 198px;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;

    background-color: #FFF;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    text-decoration: none;

    box-sizing: border-box;

    &--one-click {
    }

    &--moment-lotteries {

    }

    &-bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      object-fit: contain;
      border-radius: 20px;
      overflow: hidden;
    }

    &--one-click &-fg-img {
      position: absolute;
      left: 133px;
      bottom: -3px;
      height: 213px;
      z-index: 2;
    }

    &--moment-lotteries &-fg-img {
      position: absolute;
      top: -12px;
      left: -1px;
      width: 415px;

      z-index: 0;
    }

    &-header {
      position: relative;
      color: #212121;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;

      z-index: 1;
    }

    &-fg-img {

    }

    &-button {
      position: absolute;
      left: 16px;
      bottom: 16px;

      padding: 16px 30px;

      color: #FFF;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;

      background-color: #000;
      border-radius: 12px;

      z-index: 1;
    }
  }
}
