.for-newcomers {
  margin-bottom: 35px;

  &__header {

  }

  &__content {
    display: flex;
  }

  &__item {
    position: relative;
    height: 282px;
    width: calc(100% - 16px);
    max-width: 288px;
    margin-top: 56px;
    margin-right: 16px;
    padding: 20px 20px 24px 23px;

    color: #FFF;
    border-radius: 20px;

    flex: none;
    box-sizing: border-box;

    text-decoration: none;

    &-bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      z-index: 1;

      object-fit: cover;

      border-radius: 20px;
    }

    &-fg-img {
      position: absolute;
      top: -51px;
      left: 50%;
      width: 205px;
      height: 248px;

      transform: translateX(-50%);

      z-index: 10;

      &-src {
        position: absolute;

        animation: bounce 3s ease-in-out infinite;
        transform:translate3d(0,0,0);
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        -webkit-tap-highlight-color: transparent;
        will-change: contents;
      }

      &--5 &-src--1 {
        top: -10px;
        left: 0px;
        animation-delay: 0s;
      }

      &--5 &-src--2 {
        top: 6px;
        left: 32px;
        animation-delay: 0.6s;
      }

      &--5 &-src--3 {
        top: 21px;
        left: 63px;
        animation-delay: 1.2s;
      }

      &--5 &-src--4 {
        top: 37px;
        left: 95px;
        animation-delay: 1.8s;
      }

      &--5 &-src--5 {
        top: 53px;
        left: 127px;
        animation-delay: 2.4s;
      }

      &--4 &-src--1 {
        top: -10px;
        left: 17px;
        animation-delay: 0s;
      }

      &--4 &-src--2 {
        top: 6px;
        left: 49px;
        animation-delay: 0.6s;
      }

      &--4 &-src--3 {
        top: 21px;
        left: 80px;
        animation-delay: 1.2s;
      }

      &--4 &-src--4 {
        top: 37px;
        left: 112px;
        animation-delay: 1.8s;
      }

      &--3 &-src--1 {
        top: -10px;
        left: 34px;
        animation-delay: 0s;
      }

      &--3 &-src--2 {
        top: 6px;
        left: 66px;
        animation-delay: 0.6s;
      }

      &--3 &-src--3 {
        top: 21px;
        left: 97px;
        animation-delay: 1.2s;
      }

      &--1 {

        #Card1,
        #Card2,
        #Card3,
        #Card4,
        #Card5 {
        }

        #Card1 {
          animation-delay: 0s;
        }

        #Card2 {
          animation-delay: 0.6s;
        }

        #Card3 {
          animation-delay: 1.2s;
        }

        #Card4 {
          animation-delay: 1.8s;
        }

        #Card5 {
          animation-delay: 2.4s;
        }
      }

      &--2 {
        #Card1,
        #Card2,
        #Card3,
        #Card4,
        #Card5 {
          animation: bounce 2.4s ease-in-out infinite;
          transform:translate3d(0,0,0);
          -webkit-perspective: 1000;
          -webkit-backface-visibility: hidden;
          -webkit-transform-style: preserve-3d;
          -webkit-backface-visibility: hidden;
          -webkit-tap-highlight-color: transparent;
          will-change: contents;
        }

        #Card1 {
          animation-delay: 0s;
        }

        #Card2 {
          animation-delay: 0.6s;
        }

        #Card3 {
          animation-delay: 1.2s;
        }

        #Card4 {
          animation-delay: 1.8s;
        }
      }
    }

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;

      z-index: 2;
    }

    &-header {
      margin-bottom: 7px;
      font-size: 44px;
      line-height: 1em;
      font-weight: 500;
    }

    &-description {
      max-width: 177px;
      padding-right: 60px;

      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }

    &-from {
      position: absolute;
      right: 0;
      bottom: 0;

      padding: 12px 12px;

      color: #FFF;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;

      background-color: #000;
      border-radius: 12px;
    }
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}