.body--fix-overfow {
  overflow: hidden;
}

.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 460px;

  background-color: #FFF;

  padding: 0 10px;

  pointer-events: none;
  opacity: 0;

  transition: opacity 0.3s ease-in-out;

  overflow: auto;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &__nav {
    height: 62px;
  }

  &__menu-button {
    position: fixed;
    top: 12px;
    left: 16px;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 13px;

    background: #FFF;
    box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.13);

    border-radius: 50%;
    border: none;
    outline: none;

    z-index: 100;

    &-icon {
      width: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 55px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 10px 10px 48px;

    color: #000;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    font-family: "PT Sans", 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 50%;
      display: block;
      width: 24px;
      height: 24px;

      background-size: contain;
      background-repeat: no-repeat;

      transform: translateY(-50%);
    }

    &--all::before {
      background-image: url(./icons/lk_all_lottery.svg);
    }

    &--profile::before {
      background-image: url(./icons/lk_profile.svg);
    }

    &--balance::before {
      background-image: url(./icons/lk_balance.svg);
    }

    &--bonus-balance::before {
      background-image: url(./icons/lk_bonus.svg);
    }

    &--tickets::before {
      background-image: url(./icons/lk_tickets.svg);
    }

    &--promo::before {
      background-image: url(./icons/lk_promo.svg);
    }

    &--basket::before {
      background-image: url(./icons/lk_basket.svg);
    }

    &--friends::before {
      background-image: url(./icons/lk_friends.svg);
    }

    &--exit::before {
      background-image: url(./icons/lk_exit.svg);
    }

    &--alarm::before {
      background-image: url(./icons/lk_bell.svg);
    }

    &--live::before {
      background-image: url(./icons/lk_display.svg);
    }

    &--check::before {
      background-image: url(./icons/lk_ticket_won.svg);
    }

    &--archive::before {
      background-image: url(./icons/lk_archive.svg);
    }

    &--bonus-games::before {
      background-image: url(./icons/lk_bonus_games.svg);
    }

    &--where-new::before {
      background-image: url(./icons/lk_where_buy.svg);
    }

    &--map::before {
      background-image: url(./icons/lk_buy_question.svg);
    }

    &--sms::before {
      background-image: url(./icons/lk_sms.svg);
    }

    &--news::before {
      background-image: url(./icons/lk_news.svg);
    }

    &--company::before {
      background-image: url(./icons/lk_question.svg);
    }

    &--contacts::before {
      background-image: url(./icons/lk_tag.svg);
    }

    &--help::before {
      background-image: url(./icons/support.svg);
    }
  }

  &__divider {
    height: 1px;
    padding: 0;
    background-color: #ddd;
  }
}
