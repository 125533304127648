.all-lotteries {
  margin-bottom: 40px;

  &__header {

  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    filter: drop-shadow(0px 8px 48px rgba(0, 0, 0, 0.12));
  }

  &__item {
    position: relative;
    height: 0px;
    padding-bottom: 100%;

    background: #FFFFFF;
    border-radius: 20px;

    text-decoration: none;
    box-sizing: border-box;

    &-wrap {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      padding: 10px 8px;
      box-sizing: border-box;

      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &-img {
      position: absolute;
      top: calc(50% - 10px);
      left: 50%;

      transform: translate(-50%, -50%);
    }

    &-title {
      color: rgba(0, 0, 0, 0.4);
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;

      text-align: center;
    }
  }
}
