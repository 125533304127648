.user-tickets {
  margin-bottom: 15px;

  &__content {
    display: flex;
  }

  &__item {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
