.relevant {
  margin-bottom: 32px;

  &__header {

  }

  &__content {
    display: flex;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 316px;
    width: calc(100% - 16px);
    max-width: 288px;
    padding: 20px 16px;
    margin-right: 16px;

    /* box-shadow: 0px 8px 48px rgba(255, 109, 0, 0.32); */
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    text-decoration: none;

    background-size: cover;

    flex: none;

    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    &-tv {
      position: absolute;
      top: 14px;
      right: 16px;
    }

    &-sub-header {
      margin-bottom: 6px;

      color: rgba(255, 255, 255, 0.7);
      font-size: 10px;
      line-height: 1em;
      font-weight: 500;
    }

    &-header {
      margin-bottom: 4px;

      color: #FFF;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
    }

    &-description {
      /* max-width: 180px; */

      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }

    &-img {
      padding: 10px 0;
    }


    &-bottom {
      margin-top: auto;
    }

    &-superprize {
      margin-bottom: 6px;
    }

    &-prizes {

    }

    &-small-header {
      margin-bottom: 3px;

      color: rgba(255, 255, 255, 0.7);
      font-size: 10px;
      line-height: 1em;
    }

    &-badge {
      
    }

    &-from {
      position: absolute;
      right: 16px;
      bottom: 20px;

      padding: 10px 12px;

      color: #FFF;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;

      background-color: #000;
      border-radius: 12px;
    }
  }
}
