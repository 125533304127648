@import-normalize;

:root {
  --main-font: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

html, body {
  background-color: #f6f6f6;
}

body {
  margin: 0;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* transform:translate3d(0,0,0); */
}

.content {
  max-width: 480px;
  /* max-width: 360px; */
  margin: 0 auto;
  box-sizing: border-box;

  overflow-x: hidden;

  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
}

.header {
  margin-bottom: 20px;

  color: #000;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

.minor-header {
  margin-bottom: 8px;

  color: #909090;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.scroll-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  padding: 20px 20px;
  margin: -20px -20px;

  -webkit-overflow-scrolling: touch;

  transform:translate3d(0,0,0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 auto;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 20px;
    flex: 0 0 auto;
  }

  &--big-padding {
    padding: 50px 20px;
    margin: -50px -20px;
  }
}