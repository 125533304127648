.useful {
  margin-bottom: 24px;

  &__header {

  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;

    .useful__item {
      /* height: 161px; */
      height: 0px;
      padding-bottom: calc(50% - 8px);
      margin-right: 16px;

      justify-content: flex-end;

      flex: 1;

      .useful__item-wrap {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }

      .useful__item-content {
        margin-top: auto;
      }
    }
  }

  &__item {
    position: relative;
    height: 96px;
    margin-bottom: 17px;

    border-radius: 20px;

    color: #000;
    text-decoration: none;

    overflow: hidden;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    &--full-width {

    }

    &--half-width {

    }

    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      box-sizing: border-box;
      padding: 16px 16px;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      z-index: 1;

      object-fit: cover;
    }

    &-content {
      position: relative;
      max-width: 208px;

      z-index: 2;
    }

    &-header {
      margin-bottom: 4px;

      font-size: 18px;
      line-height: 1em;
      font-weight: 500;
    }

    &-description {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
    }
  }
}
