.fixed-cart {
  position: fixed;
  width: calc(100vw - 30px);
  max-width: 450px;
  bottom: 10px;
  bottom: calc(env(safe-area-inset-bottom) + 10px);
  left: 50%;
  display: flex;
  padding: 7px 9px;
  justify-content: space-between;

  transform: translateX(-50%);

  background: #FFFFFF;
  /* Stoloto shadow */
  
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.12);
  border-radius: 30px;

  z-index: 1000;

  &__left {
    padding: 10px 10px 10px 40px;

    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 16px;
      display: block;
      width: 27px;
      height: 27px;

      transform: translateY(-50%);

      background-image: url(./tickets.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__right {
    padding: 10px 15px;

    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    border-radius: 30px;

    background: #FFC632;
  }
}
