.main {
  position: relative;

  &--unauthenticated {
    background-color: #FFF;
  }

  &__background-content {
    padding-top: 12px;

    .main--authenticated & {
      min-height: 216px;
    }

    &-wrap {

      .main--authenticated & {
        position: fixed;
        max-width: calc(480px - 16px * 2);
        overflow: hidden;
        padding: 0 16px;
  
        transform:translate3d(0,0,0);

        @media (max-width: 480px) {
          max-width: calc(100vw - 16px * 2);
        }
      }
    }
  }

  &__foreground-content {
    position: relative;
    padding: 20px 16px 0;

    background-color: #FFF;

    z-index: 10;

    transform:translate3d(0,0,0);

    .main--authenticated & {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
  
      box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.13);
    }
  }
}
