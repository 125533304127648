.page-header {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 480px;
  display: flex;
  align-items: center;

  margin-bottom: 12px;
  padding: 12px 12px 0;

  transform: translateX(-50%);

  box-sizing: border-box;

  z-index: 1000;

  &__menu-button {
    width: 40px;
    height: 40px;

    margin-right: 13px;

    background: #FFF;
    box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.13);

    border-radius: 50%;
    border: none;

    outline: none;

    &-icon {

    }
  }

  &__right {
    margin-left: auto;
  }

  &__balance {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin-top: -1px;

    background: #FFF;
    box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.13);

    border-radius: 12px;
    border: none;

    &-number {
      font-size: 12px;
      line-height: 1em;
      font-weight: bold;
      letter-spacing: 0.2px;
    }

    &-divider {
      display: block;
      height: 18px;
      width: 1px;
      margin: 0 7px;

      background-color: #DDD;
    }

    &-update {
      margin-top: 3px;
      margin-left: -2px;
      margin-right: -4px;
      padding: 0;

      background: none;

      border: none;

      &-icon {

      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    background: #FFF;
    box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.13);

    border-radius: 50%;
    border: none;

    &-icon {

    }
  }
}
