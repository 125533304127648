.last-games {
  margin-bottom: 15px;

  &__content {
    display: flex;
  }

  &__item {
    display: inline-block;
    height: 40px;

    padding: 0px 10px;
    margin-right: 8px;

    background: #FFFFFF;
    box-shadow: 0px 9px 37px rgba(0, 0, 0, 0.13);
    border-radius: 19px;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      height: 100%;
    }
  }
}
