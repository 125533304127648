.badge {
  position: relative;
  display: inline-block;

  color: #000;
  font-size: 18px;
  line-height: 1em;
  font-weight: 500;

  background-color: #FFF;
  border-radius: 17px;

  overflow: hidden;

  &--small {
    color: #000;
    font-size: 12px;

    background-color: rgba(255, 255, 255, 0.8);

    .badge__wrap {
      margin: 3px 8px;
    }
  }

  &__wrap {
    position: relative;
    margin: 5px 10px;

    overflow: hidden;
  }

  &__item {
    display: none;

    opacity: 0;

    &--previous {
      position: absolute;
      width: 100px;
      top: 0px;
      left: 0px;
      display: inline-block;
      animation: fade-out 0.25s ease-in;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    &--visible {
      display: inline-block;
      opacity: 1;
      animation: fade-in 0.25s ease-in;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade-in{
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
@keyframes fade-out{
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}