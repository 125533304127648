.logo {
  margin-top: 6px;
  margin-left: 69px;
  margin-bottom: 4px;

  .main--authenticated & {
    margin-left: 53px;
    margin-bottom: 21px;
  }

  &__icon {

  }
}
