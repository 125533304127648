.inexpensive-games {
  margin-bottom: 30px;

  &__header {

  }

  &__content {
    display: flex;
  }

  &__item {
    position: relative;
    display: block;
    width: 191px;
    min-height: 320px;
    padding: 16px 16px;
    margin-right: 16px;

    background-color: #FFF;

    border-radius: 20px;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.12);

    text-decoration: none;

    box-sizing: border-box;

    flex: none;

    &:last-child {
      margin-bottom: 0;
    }

    &-sub-header {
      margin-bottom: 6px;

      color: rgba(0, 0, 0, 0.435);
      font-size: 10px;
      line-height: 1em;
      font-weight: 500;
    }

    &-header {
      max-width: 165px;
      /* margin-bottom: 8px; */

      color: #212121;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
    }

    &-imgcontainer {
      display: flex;
      height: 92px;

      align-items: center;
      justify-content: center;
    }

    &-img {
      padding: 15px 0;
      height: 100%;
      box-sizing: border-box;
      object-fit: contain;
    }

    &-superprize {
      margin-bottom: 6px;
    }

    &-prizes {
      margin-bottom: 14px;
    }

    &-small-header {
      margin-bottom: 3px;

      color: #909090;
      font-size: 10px;
      line-height: 1em
    }

    &-badge {
      background-color: #EEEEEE;
    }

    &-from {
      display: inline-block;
      padding: 10px 12px;

      color: #FFF;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;

      background-color: #000;
      border-radius: 12px;
    }
  }
}
