.user-ticket-item {
  position: relative;
  display: block;
  height: 52px;
  width: 64px;

  padding: 9px 8px;

  border-radius: 8px;

  color: #000;
  text-decoration: none;

  overflow: hidden;
  box-sizing: border-box;

  &__content {
    position: relative;
    z-index: 2;
  }

  &__time {
    margin-bottom: 3px;

    font-size: 12px;
    line-height: 1em;
    font-weight: 500;
  }

  &__number {
    display: inline-flex;
    min-width: 16px;
    height: 16px;

    align-content: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    color: #FFF;
    font-size: 10px;
    line-height: 1em;

    border-radius: 15px;
    background-color: #000;

    &.--more-than-ten {
      padding: 0 3px;
    }
  }

  &__logo {
    position: absolute;
    width: 44px;
    left: calc(50% - 43px/2 + 17px);
    top: calc(50% - 43px/2 + 8px);

    transform: rotate(-30deg);

    z-index: 1;
  }
}
