.special-games {
  margin-bottom: 33px;

  &__header {

  }

  &__content {
    display: flex;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 390px;
    width: calc(100% - 16px);
    max-width: 288px;
    padding: 16px 16px;
    margin-right: 16px;

    box-shadow: 0px 8px 48px rgba(25, 61, 96, 0.3);
    border-radius: 20px;
    text-decoration: none;

    flex: none;

    box-sizing: border-box;
    overflow: hidden;

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;

      z-index: 0;
    }

    &-content {
      position: relative;
      display: flex;
      height: 100%;
      flex-direction: column;

      z-index: 1;
    }

    &-icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    &-sub-header {
      margin-bottom: 5px;

      color: rgba(255, 255, 255, 0.7);
      font-size: 10px;
      line-height: 1em;
      font-weight: 500;
    }

    &-header {
      color: #FFF;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
    }

    &-button {
      padding: 16px;
      margin-top: auto;

      color: #000;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      text-align: center;

      background: #FFCD17;
      border-radius: 12px;
    }
  }
}
