.stories {
  margin-bottom: 17px;

  &__content {
    display: flex;
  }

  &__item {
    position: relative;
    display: inline-block;
    height: 100px;
    width: 92px;
    margin-right: 10px;

    border-radius: 12px;
    overflow: hidden;

    flex: none;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      position: absolute;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &-header {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 12px;
      z-index: 1;

      color: #000;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
    }
  }
}
