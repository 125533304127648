.stats {
  display: flex;
  padding: 12px 12px;
  margin-bottom: 20px;

  background: #F6F6F6;
  border-radius: 20px;

  &__col {
    position: relative;
    padding-left: 29px;
    margin-right: 12px;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
  }

  &__header {
    margin-top: 6px;
    margin-bottom: 3px;

    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
  }

  &__description {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }
}
